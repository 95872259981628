export class Banner {
  id: number | undefined;
  seq: number | undefined;
  image: string | undefined;
  description: string | undefined;
  startDate: Date | undefined;
  expireDate: Date | undefined;
  link: string | undefined;
  externalSite: boolean = false;
  gaTrack: string | undefined;
}

export interface UploadFileResp {
  fileName: string;
  fileSize: number;
  fileType: string;
}

export class BannerSeq {
  id: number | undefined;
  seq: number | undefined;

  constructor(data: BannerSeq) {
    Object.assign(this, data);
  }
}

export enum BannerStatus {
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
  EXPIRED = 'EXPIRED',
}

export enum UploadFilesType {
  Banner = 'banner',
  IdeaImage = 'idea-image',
  IdeaFile = 'idea-file',
  Cover = 'cover',
}
