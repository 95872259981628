import ideaCollectionService from '@api/idea-collection/idea-collection.service';
import { Button, Modal, MultiSelect, NumberInput, SelectItem } from '@mantine/core';
import { useForm } from '@mantine/form';
import {
  CollectionCategory,
  CollectionPagination,
  IdeaCollectionCategorySave,
  IdeaCollectionForm,
  IdeaCollectionGroupByYear,
  IdeaCollectionSave,
} from '@models/IdeaCollection';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { finalize } from 'rxjs';

interface ModalEditIdeaCollectionProps {
  ideaCollection: IdeaCollectionGroupByYear | undefined;
  onCancel: () => void;
  onConfirm: () => void;
}

const ModalEditIdeaCollection = ({ ideaCollection, onCancel, onConfirm }: ModalEditIdeaCollectionProps): JSX.Element => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [itemSelect, setItemSelect] = useState<SelectItem[]>([]);

  const defaultCollection: string[] | undefined = useMemo(
    () => ideaCollection?.ideaCollections?.map((c) => c.collectionId.toString()),
    [ideaCollection]
  );

  const fetchCategories = useCallback(() => {
    ideaCollectionService.getCategories(1, 9999).subscribe((resp: CollectionPagination<CollectionCategory>) => {
      const itemSelects = resp.data.map((c) => ({
        label: c.name,
        value: `${c.id}`,
      }));
      setItemSelect(itemSelects);
    });
  }, []);

  useEffect(() => fetchCategories(), []);

  const form = useForm<IdeaCollectionForm>({
    initialValues: {
      originalYear: ideaCollection?.year,
      year: ideaCollection?.year,
      collectionCategory: defaultCollection ?? [],
    },
    validate: {
      year: (value: number | undefined) => (value ? null : 'กรุณากรอก'),
      collectionCategory: (value: string[]) => (value?.length > 0 ? null : 'กรุณาเลือก'),
    },
  });

  const handleSubmit = (value: IdeaCollectionForm): void => {
    if (form.isValid()) {
      const ideaCollections: IdeaCollectionCategorySave[] = value.collectionCategory.map((categoryId) => {
        const found = ideaCollection?.ideaCollections.find((ic) => ic.collectionId.toString() === categoryId);
        return {
          id: found?.id,
          collectionId: parseInt(categoryId),
        };
      });

      const req: IdeaCollectionSave = {
        originalYear: value.originalYear,
        year: value.year,
        ideaCollections: ideaCollections,
      };

      ideaCollectionService
        .saveIdeaCollection(req)
        .pipe(finalize(() => setSubmitting(false)))
        .subscribe({
          next: () => onConfirm(),
          error: (err) => {
            console.error('Save Idea Collection:', err);
          },
        });
    }
  };

  return (
    <Modal
      centered
      opened={true}
      closeOnClickOutside={false}
      withCloseButton={false}
      onClose={() => onCancel()}
      title={
        <div className="flex flex-col">
          <span className="text-xl">เพิ่มปีและจัดการ Collection ในปีนั้น</span>
          <span className="text-sm text-disabled2">ถ้าในปีนั้นๆ มีชื่อหมวดหมู่ใหม่ ให้ไป ‘เพิ่มชื่อ Collection’ ให้เรียบร้อยก่อน</span>
        </div>
      }
      size={500}
    >
      <form onSubmit={form.onSubmit((values) => handleSubmit(values))} className="grid gap-4">
        <NumberInput placeholder="ใส่ตัวเลขเท่านั้น" label="ปี" className="w-[460px]" withAsterisk {...form.getInputProps('year')} />
        <MultiSelect
          data={itemSelect}
          label="เลือกหมวดหมู่ Collection"
          placeholder="เลือกหมวดหมู่ Collection"
          nothingFound="Nothing found"
          className="w-[460px]"
          {...form.getInputProps('collectionCategory')}
        />

        <div className="flex justify-between">
          <Button
            variant="outline"
            color="red"
            className="rounded-full px-4 text-lg font-normal text-error no-underline hover:bg-red-100"
            onClick={() => onCancel()}
          >
            ยกเลิก
          </Button>
          <Button
            type="submit"
            className="rounded-full bg-primary px-4 text-lg font-normal text-black no-underline hover:bg-primary-300"
            disabled={!form.isValid() || submitting}
          >
            ยืนยันข้อมูล
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default ModalEditIdeaCollection;
