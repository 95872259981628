import ideaCollectionService from '@api/idea-collection/idea-collection.service';
import { IdeaCollection, IdeaCollectionGroupByYear } from '@models/IdeaCollection';
import _ from 'lodash';
import React, { useCallback, useState } from 'react';
import CollectionCategorySection from './components/CollectionCategorySeciton';
import IdeaCollectionByYearSection from './components/IdeaCollectionByYearSection';

const IdeaCollections = (): JSX.Element => {
  const [ideaGroup, setIdeaGroup] = useState<IdeaCollectionGroupByYear[]>([]);

  const fetchCollection = useCallback(() => {
    ideaCollectionService.getAllIdeaCollection().subscribe((resp: IdeaCollection[]) => {
      const groupYear = _.groupBy(resp, 'year');
      const grouped = Object.entries(groupYear)
        .map(([key, value]) => ({ year: parseInt(key), ideaCollections: value }))
        .sort((a, b) => (a.year < b.year ? 1 : -1));
      setIdeaGroup(grouped);
    });
  }, []);

  return (
    <>
      <div className="mb-6 grid gap-6">
        <div className="mb-6">
          <article className="prose">
            <h1 className="mb-0">จัดการ Idea Collection</h1>
          </article>
        </div>

        <CollectionCategorySection callback={fetchCollection} />
        <IdeaCollectionByYearSection ideaGroup={ideaGroup} fetchData={fetchCollection} />
      </div>
    </>
  );
};

export default IdeaCollections;
